<template>
  <div class="test">
    <transition name="fade" mode="out-in">
      <loader-block
        style="font-size: 40px; height: 30vh"
        v-if="pending"
      ></loader-block>
      <div v-if="!pending && test" class="inner test__inner">
        <test-body :test-info="test" v-if="isTestActive && !isScormTest" />
        <test-scorm :test-info="test" v-else-if="isTestActive && isScormTest" />
        <template v-else>
          <test-description :test="test" />
          <test-results :results="test.results" v-if="test.results.length > 0 && !isScormTest" />
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import TestDescription from "../../components/test-description/test-description.vue";
import TestResults from "../../components/test-results/test-results.vue";
import TestBody from "../../components/test-body/test-body.vue";
import TestScorm from "../../components/test-scorm/test-scorm.vue";
import LoaderBlock from "../../components/loader/loader.vue";
import api from "../../api";

export default {
  components: {
    TestDescription,
    TestResults,
    TestBody,
    LoaderBlock,
    TestScorm
  },
  data() {
    return {
      test: null,
      pending: false,
    };
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  computed: {
    isTestActive() {
      return this.$store.state.Tests.isTestActive;
    },
    isScormTest() {
      return this.test.type === "scorm"
    }
  },
  methods: {
    async fetchTestInfo() {
      try {
        this.pending = true;
        let { data } = await api.tests.getTest(this.$route.params.id);
        this.test = data;
      } catch (eX) {
        console.error(eX);
      }
      this.pending = false;
    },
  },
  async mounted() {
    this.fetchTestInfo();
  },
  watch: {
    isTestActive(active) {
      if (active) return;
      this.fetchTestInfo();
    },
  },

};
</script>

<style src="./test.less" lang="less" />
