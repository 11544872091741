<template>
  <div :class="bem('loader')">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'LoaderBlock',
}
</script>

<style lang="less" src="./loader.less"></style>
