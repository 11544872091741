import Vue from 'vue';
import { camelToKebab, reachGoal, getStaticUrl, validateEmail } from '@/tools';

Vue.mixin({
    methods: {
        reachGoal,
        getStaticUrl,
        validateEmail,

        bem(name, mods = {}) {
            const result = [name];

            for (let [mod, value] of Object.entries(mods)) {
                if (value === true) {
                    result.push(`${name}_${camelToKebab(mod)}`);
                } else if (value) {
                    result.push(`${name}_${camelToKebab(mod)}_${camelToKebab(value)}`);
                }
            }

            return result.join(' ');
        },
        downloadCert(hash) {
            if (!hash) {
                this.$dialog.show({
                    title: "Ошибка",
                    message: "Неудалось загрузить сертификат",
                    icon: "ico-modal-5.svg",
                    closeOnConfirm: false,
                });
                return
            }
            const url = new URL(process.env.VUE_APP_API_URL)
            url.pathname = `/api/certificate/${hash}`
            window.open(url.href, '_blank');
        }
    },
});
