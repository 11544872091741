<template>
  <div class="question-card">
    <h4 class="question-card__title" v-html="title"/>
    <p class="question-card__description" v-html="description"/>
    <img class="question-card__image" :src="image" alt="">
  </div>
</template>

<script>
import ButtonBlock from "../../components/button/button";

export default {
  components: {
    ButtonBlock
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    image: {
      required: false
    }
  }
}
</script>

<style src="./question-card.less" lang="less" />
