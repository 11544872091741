import cookies from 'js-cookie';

export function setToken({ value, duration }) {
    const expires = duration === 'long' ? 30 : 1 / 24;
    cookies.set('jwt', { value, duration }, { expires });
}

export function getToken() {
    return cookies.getJSON('jwt');
}

export function deleteToken() {
    return cookies.remove('jwt');
}