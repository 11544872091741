import { getInstance } from '../tools';

export function getTests(available) {
  return getInstance().get('/api/tests', {
      params: {
          'type': available ? 0 : 1,
      },
  });
}

export function getTest(id) {
    return getInstance().get('/api/tests/'+id);
}

export function startTest(id) {
    return getInstance().post(`/api/tests/${id}/start`);
}

export function finishTest(id, answers) {
    return getInstance().post(`/api/tests/${id}/finish`, {
        answers
    });
}