import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/login/login";
import Invite from "../views/invite/invite";
import Home from "../views/home/home";
import Test from "../views/test/test.vue";
import store from "@/store";
import { getToken } from '../tools/tokenStore';
import api from "@/api";

Vue.use(VueRouter);

const routes = [
  {
    path: "/invite",
    name: "invite",
    component: Invite,
    props: route => ({
      token: route.query.token,
    }),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { auth: true },
  },
  {
    path: "/tests/:id",
    name: "test-id",
    component: Test,
    meta: { auth: true },
  },
  {
    path: "*",
    beforeEnter: (to, from, next) => {
      next("/");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = getToken();

  const testId = router.currentRoute.params.id
  async function finishTest() {
    try {
      const test = await api.tests.getTest(testId)
      try {
        if (test.data.type !== 'scorm') {
          await api.tests.finishTest(testId, {})
        }
        next()
      } catch (e) {
        console.log(e)
      }
    }
    catch (e) {
      console.log(e)
    }

  }

  if (to.name === 'login' && token) {
    next('/');
  } else if (to.name === 'invite' && to.query.token) {
    next();
  } else if (to.name !== 'login' && !token) {
    next('/login');
  } else {
    // if (store.state.Tests.isTestActive) {
    //   router.app.$dialog.show({
    //     title: "Вы точно хотите выйти?",
    //     message: "Прогресс курса не будет сохранен. Вы сможете продолжить проходить, перейдя на нужный слайд через оглавление. Тестирование будет засчитано неудачным.",
    //     icon: "ico-modal-2.svg",
    //     buttons: {
    //       confirm: "Остаться",
    //       cancel: "Выйти",
    //     },
    //     onCancel: finishTest,
    //   });
    //   return
    // }
    next();
  }
});

export default router;
