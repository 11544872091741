export function clone(object) {
    return JSON.parse(JSON.stringify(object));
}

export function camelToKebab(string) {
    return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
}

export function reachGoal(name) {
    if (window.ym) {
        window.ym(null, 'reachGoal', name);
    }
}

export function getStaticUrl(path, isCssUrl = false) {
    const staticUrl = `${window.STATIC_PATH}/${path}`;
    return isCssUrl ? `url(${staticUrl})` : staticUrl;
}

export function validateEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
}

/**
 * Plural forms for russian words
 * @param  {Integer} count quantity for word
 * @param  {Array} words Array of words. Example: ['депутат', 'депутата', 'депутатов'], ['коментарий', 'коментария', 'комментариев']
 * @return {String}        Count + plural form for word
 */
export function pluralize(count, words) {
    var cases = [2, 0, 1, 1, 1, 2];
    return count + ' ' + words[ (count % 100 > 4 && count % 100 < 20) ? 2 : cases[ Math.min(count % 10, 5)] ];
}

export function minToHours(timeForPassingTest) {
    const hours = parseInt(timeForPassingTest / 60);
    const minutes = timeForPassingTest % 60;

    let timeInfo = '';
    if (hours >= 1) {
        timeInfo = pluralize(hours, ['час', 'часа', 'часов']) + ' ';
    }
    if (minutes) {
        timeInfo += pluralize(minutes, ['минута', 'минуты', 'минут']);
    }

    return timeInfo;
}
