<template>
  <div class="test-results">
    <div class="test-results__inner">
      <h3 class="test-results__title">Ваши результаты</h3>
      <div class="test-results__table">
        <div class="test-results__table-row test-results__table-row_titles">
          <div
            v-for="(title, index) in tableTitles"
            :key="index"
            class="test-results__table-el"
            v-html="title"
          />
        </div>
        <div
          v-for="(row, index) in tableData"
          :key="index"
          class="test-results__table-row"
        >
          <div
            class="test-results__table-el"
            v-for="(el, idx) in row.slice(0, -1)"
            :key="idx"
            v-html="el"
          />
          <LabelBlock :status="row[row.length - 1]" />
        </div>
      </div>
      <div class="test-results__dropdowns">
        <div
          v-for="(data, index) in tableData"
          :key="index"
          class="test-results__dropdown"
          @click="dropdownClick"
        >
          <LabelBlock class="label_status" :status="data[data.length - 1]" />
          <div
            v-for="(title, idx) in tableTitles"
            :key="idx + title"
            class="test-results__dropdown-container"
          >
            <div class="test-results__dropdown-title">{{ title }}</div>
            <div
              class="test-results__dropdown-el"
              :class="{
                'test-results__dropdown-el_preview': idx === 0,
              }"
              v-html="data.slice(0, -1)[idx]"
            ></div>
          </div>
          <LabelBlock :status="data[data.length - 1]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LabelBlock from "../../components/label/label.vue";

export default {
  props: {
    results: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    LabelBlock,
  },
  data: () => ({
    tableTitles: [
      "Дата и время",
      "Верных ответов",
      "Время прохождения",
      "Итог",
    ],
    // tableData: [
    //   ["00.00.0000 в 00:00", "10 из 10", "00:00:00", 1],
    //   ["00.00.0000 в 00:00", "2 из 10", "00:00:00", 2],
    //   ["00.00.0000 в 00:00", "7 из 10", "00:00:00", 3],
    //   ["00.00.0000 в 00:00", "10 из 10", "00:00:00", 1],
    //   ["00.00.0000 в 00:00", "2 из 10", "00:00:00", 2],
    //   ["00.00.0000 в 00:00", "7 из 10", "00:00:00", 3],
    // ],
  }),
  methods: {
    dropdownClick(e) {
      e.target
        .closest(".test-results__dropdown")
        .classList.toggle("test-results__dropdown_opened");
    },
  },
  computed: {
    tableData() {
      return this.results.map((i) => [
        i.finishedAt,
        `${i.correctAnswersCount} из ${i.answersCount}`,
        i.duration,
        i.result,
      ]);
    },
  },
};
</script>

<style src="./test-results.less" lang="less" />
