<template>
	<div
		:class="bem('button', { type, size, disabled, pending })"
	>
		<div class="button__text">
			<slot/>
		</div>
		<div
			v-if="pending"
			class="button__loader"
		>
			<svg class="button__loader-icon" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M10 2C5.58172 2 2 5.58172 2 10C2 10.5523 1.55228 11 1 11C0.447715 11 0 10.5523 0 10C0 4.47715 4.47715 0 10 0C10.5523 0 11 0.447715 11 1C11 1.55228 10.5523 2 10 2ZM19 9C19.5523 9 20 9.44771 20 10C20 15.5228 15.5228 20 10 20C9.44771 20 9 19.5523 9 19C9 18.4477 9.44771 18 10 18C14.4183 18 18 14.4183 18 10C18 9.44771 18.4477 9 19 9Z" fill="white"/>
			</svg>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'button-block',
		props: {
			type: {
				type: String,
				default: 'primary',
			},
			size: {
				type: String,
				default: 'default',
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			pending: {
				type: Boolean,
				default: false,
			},
			href: {
				type: String,
				default: null,
			},
			to: {
				type: [String, Object],
				default: null,
			},
		},
		computed: {
			options() {
				if (this.href) {
					return {
						is: 'a',
						target: '_blank',
						to: this.href,
					};
				}

				if (this.to) {
					return {
						is: 'router-link',
						to: this.to,
					};
				}

				return {
					is: 'div',
				};
			},
		},
	}
</script>

<style src="./button.less" lang="less" />