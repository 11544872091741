<template>
  <div class="test-card">
    <div class="test-card__box test-card__box_left">
      <div class="test-card__text-wrapper">
        <div v-if="isScorm" class="test-card__text test-card__text_info">
          Интерактивный тренинг
          <span v-if="isScormPassed" class="test-card__text test-card__text_status">Пройдено</span>
        </div>
        <div v-else class="test-card__text test-card__text_info">{{ info }}</div>
      </div>
      <div class="test-card__text test-card__text_title">{{ title }}</div>
      <div class="test-card__text test-card__text_description">
        {{ description }}
      </div>
    </div>
    <div class="test-card__box test-card__box_right">
      <button-block v-if="showBtn" @click.native="$emit('click-pass')">
        {{ buttonContent }}
      </button-block>
    </div>
  </div>
</template>

<script>
import ButtonBlock from "../../components/button/button";

export default {
  components: {
    ButtonBlock,
  },
  props: {
    hash: String,
    id: [String, Number],
    index: Number,
    info: String,
    title: String,
    description: String,
    passed: Boolean,
    type: String,
    isScormPassed: Boolean,
    endedAt: String,
    localEndedAt:String,
    isAvailable: {
      type: Boolean,
      required: false,
      default: false
    },
    isCertificatePrintable: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {

    buttonContent() {
      const scormData = JSON.parse(localStorage.getItem('scormData') || '{}');
      const isHaveCourseData = this.hash ? scormData?.[this.id]?.[this.hash]?.['cmi.suspend_data'] : false;

      if (this.isScormPassed) {
        return "Повторить"
      }
      else {
        return this.passed ? "Подробнее" : isHaveCourseData ? "Продолжить" : "Пройти";
      }
    },
    showBtn() {
      if (this.type !== 'scorm') {
        return this.isCertificatePrintable
      } else {
        return this.isAvailable
      }
    },
    isScorm() {
      return this.type === "scorm"
    },
  },
};
</script>

<style src="./test-card.less" lang="less" />
