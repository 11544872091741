<template>
  <div class="test-scorm">
    <loader-block
      style="font-size: 40px; height: 30vh"
      v-if="pending"
    ></loader-block>
    <div v-else class="test-scorm__wrapper">
      <scorm-player
        :id="testId"
        :hash="hash"
        class="test-scorm__player"
        :path='`/api/scorm/${hash}`'
        @finish="finish"
        @set-value="setValue"
      ></scorm-player>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import api from "../../api"
import LoaderBlock from "../../components/loader/loader.vue";
import ScormPlayer from '../scorm-player/scorm-player.vue';

export default {
  components: {
    ScormPlayer,
    LoaderBlock,
    ScormPlayer,
  },

  data() {
    return {
      statusTest: "",
      test: null,
      pending: false
    }
  },

  props: {
    testInfo: {
      type: Object
    }
  },

  methods: {
    async initTest() {
      this.test = null;
      this.pending = true;
      try {
        let { data } = await api.tests.startTest(this.testId);
        this.test = data;
        this.pending = false;
      } catch (eX) {
        console.error(eX);
        this.pending = false;
      }
    },

    async finishTest() {
      if (this.testInfo.isPassed && this.statusTest === "completed") {
        this.openSuccessTestModal();
      } else if (this.testInfo.isPassed && this.statusTest !== "completed") {
        this.leaveTesting()
      } else {
        try {
          if (this.statusTest === "completed") {
            this.openSuccessTestModal(data.certificateHash);
          }
        } catch (eX) {
          console.error(eX);
          this.leaveTesting();
          this.pendingResults = false;
        }
      }
    },

    leaveTesting() {
      this.$store.commit("setTestState", false);
      this.$router.push(`/`)
    },

    openSuccessTestModal() {
      this.$dialog.show({
        title: "Поздравляем!",
        message: "Вы прошли интерактивный тренинг.",
        icon: "ico-modal-3.svg",
        closeOnCancel: false,
        buttons: {
          confirm: "Хорошо",
        },
        onConfirm: this.leaveTesting,
      });
    },
    onCloseTab(event) {
      event.preventDefault();
      event.returnValue = "";
    },
    finish() {
      this.finishTest()
    },
    async setValue(key, value) {
      if (value === "completed") {
        try {
          let { data } = await api.tests.finishTest(this.testId, []);
          this.testInfo.isPassed = true
        } catch (error) {
          console.error(error)
        }
        this.statusTest = value
      }
    },
  },

  computed: {
    hash() {
      return this.testInfo.hash
    },
    testId() {
      return this.$route.params.id
    },
    testPath() {
      return `${process.env.VUE_APP_API_URL}/api/scorm/${this.hash}`
    },
  },

  async mounted() {
    this.initTest();

  },

  created() {
    this.$store.commit("setTestState", true);
  },

  beforeDestroy() {
    this.$store.commit("setTestState", false);
  },
};
</script>

<style src="./test-scorm.less" lang="less" />
