<template>
	<div class="input">
		<input
			ref="input"
			:type="typeInput"
			:placeholder="placeholder"
			@input="$emit('input', $event.target.value)"
			:class="bem('input__elem', { error, password: isPassword })"
			@keyup.enter="onEnter"
			@blur="onBlur"
		/>

		<div
			v-if="isPassword"
			@mousedown="handlerSwitch(true)"
			@mouseup="handlerSwitch(false)"
			class="input__switch"
		>
			<div
				v-if="!pressSwitch"
				class="input__switch-icon input__switch-icon_show"
			/>
			<div
				v-else
				class="input__switch-icon input__switch-icon_hide"
			/>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			type: {
				type: String,
				default: 'text',
			},
			placeholder: {
				type: String,
				default: null,
			},
			error: {
				type: Boolean,
				default: false,
			},
		},
		data: () => ({
			pressSwitch: false,
		}),
		methods: {
			handlerSwitch(status) {
				this.pressSwitch = status;
			},
			focus() {
				this.$refs.input.focus();
			},
			onEnter() {
				this.$emit('on-enter')
			},
			onBlur() {
				this.$emit('on-blur')
			}
		},
		computed: {
			typeInput() {
				return this.pressSwitch ? 'text' : this.type;
			},
			isPassword() {
				return this.type === 'password'
			}
		},
	}
</script>

<style src="./input.less" lang="less" />