<template>
	<footer class="footer">
		<div class="inner footer__inner">
			<div class="footer__copyright">&copy;&nbsp;2013&nbsp;&mdash; {{ year }} Swordfish Security</div>
			<special-button
				@click.native="scrollToTop"
				class="footer__up"
			>
				<template #icon></template>
				<template #text>Наверх</template>
			</special-button>
		</div>
	</footer>
</template>

<script>
	import animateScrollTo from 'animated-scroll-to';

	import SpecialButton from '../special-button/special-button';

	export default {
		components: {
			SpecialButton,
		},
		methods: {
			scrollToTop(){
				animateScrollTo(0);
			},
		},
		computed: {
			year() {
				return new Date().getFullYear();
			},
		},
	}
</script>

<style src="./footer.less" lang="less" />