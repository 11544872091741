<template>
	<div class="special-button">
		<div class="special-button__box">
			<div class="special-button__icon">
				<slot name="icon" />
			</div>
		</div>
		<div class="special-button__text">
			<slot name="text" />
		</div>
	</div>
</template>

<script>
	export default {
	
	}
</script>

<style src="./special-button.less" lang="less" />