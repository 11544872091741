<template>
  <div class="test-body">
    <transition name="fade" mode="out-in">
      <loader-block
        style="font-size: 40px; height: 30vh"
        v-if="pending && !currentQuestion"
      ></loader-block>
      <div class="test-body__inner" v-if="currentQuestion">
        <transition name="fade" mode="out-in">
          <div class="test-body__columns" :key="currentQuestionIndex">
            <div class="test-body__column">
              <div class="test-body__top">
                <div class="test-body__value">
                  <div
                    class="test-body__value-ico test-body__value-ico_time"
                  ></div>
                  <div class="test-body__value-text">{{ timerTime }}</div>
                </div>
                <div class="test-body__value">
                  <div
                    class="test-body__value-ico test-body__value-ico_counter"
                  ></div>
                  <p class="test-body__value-text">
                    {{ currentQuestionIndex + 1 }} из {{ questionsQuantity }}
                  </p>
                </div>
              </div>
              <question-card
                class="test-body__question-card"
                :title="currentQuestion.title"
                :description="currentQuestion.description"
                :image="currentQuestion.image"
              />
              <button-block
                v-if="!$store.getters.isMobile"
                @click.native="onClickNext"
                :disabled="isNextDisabled"
                :pending="pendingResults"
                >Далее</button-block
              >
            </div>
            <div class="test-body__column">
              <div class="test-body__warn">
                <template v-if="isMultipleAnswers">
                  <div class="test-body__ckecks-ico"></div>
                  <p class="test-body__warn-text">
                    Несколько вариантов ответов
                  </p>
                </template>
                <template v-else>
                  <div class="test-body__ckeck-ico"></div>
                  <p class="test-body__warn-text">Один вариант ответа</p>
                </template>
              </div>
              <div
                v-for="answer in currentQuestion.answers"
                :key="answer.id"
                class="test-body__answer"
                @click="onAnswerSelect($event, answer.id)"
              >
                <answer-card
                  :index="answer.id"
                  :text="answer.text"
                  :image="answer.imageUrl"
                  :class="{
                    'answer-card_selected': selectedAnswers.includes(answer.id),
                  }"
                />
              </div>
            </div>
            <button-block
              @click.native="onClickNext"
              class="test-body__mobile-button"
              v-if="$store.getters.isMobile"
              :disabled="isNextDisabled"
              :pending="pendingResults"
              >Далее</button-block
            >
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import QuestionCard from "../../components/question-card/question-card.vue";
import AnswerCard from "../../components/answer-card/answer-card.vue";
import ButtonBlock from "../../components/button/button";
import LoaderBlock from "../../components/loader/loader.vue";

import api from "../../api";
import { pluralize } from "../../tools";
import Timer from "../../tools/timer";
import { mapGetters } from "vuex";

export default {
  components: {
    QuestionCard,
    AnswerCard,
    ButtonBlock,
    LoaderBlock,
  },
  data: () => ({
    test: null,
    pending: false,
    pendingResults: false,
    currentQuestionIndex: 0,
    selectedAnswers: [],
    answers: [],
    isFinished: null,
    timer: null,
    timerTime: "",
  }),
  props: {
    testInfo: {
      type: Object,
      required: false,
      default: () => {}
    },
  },
  computed: {
    ...mapGetters({
      isFullFilledName: "isFullFilledName"
    }),
    testId() {
      return this.$route.params.id;
    },
    questions() {
      return this.test?.questions || [];
    },
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    questionsQuantity() {
      return this.questions.length;
    },
    isLastQuestion() {
      return this.questionsQuantity === this.currentQuestionIndex + 1;
    },
    isNextDisabled() {
      return [this.selectedAnswers.length < 1, this.isFinished].includes(true);
    },
    isMultipleAnswers() {
      return this.currentQuestion.multipleAnswers;
    },
  },
  methods: {
    onCloseTab(event) {
      event.preventDefault();
      event.returnValue = "";
    },
    onTick(time) {
      this.timerTime = time;
    },
    onTimeOut() {
      this.openTimeEndTestModal();
    },
    async initTest() {
      this.test = null;
      this.currentQuestionIndex = 0;
      this.selectedAnswers = [];
      this.answers = [];
      this.isFinished = false;
      this.pending = true;
      try {
        let { data } = await api.tests.startTest(this.testId);
        this.test = data;
        this.pending = false;
        this.timer.start(this.test.startedAt, this.test.timeForPassingTest);
      } catch (eX) {
        console.error(eX);
        this.pending = false;
      }
    },
    onAnswerSelect(e, answerId) {
      if (this.isFinished) return;

      const isSelected = this.selectedAnswers.includes(answerId);

      if (this.isMultipleAnswers) {
        this.selectedAnswers.push(answerId);
      } else {
        this.selectedAnswers = [answerId];
      }

      if (isSelected) {
        this.selectedAnswers = this.selectedAnswers.filter(
          (i) => i !== answerId
        );
      }
    },
    onClickNext() {
      if (!this.selectedAnswers) return;
      this.answers.push({
        questionId: this.currentQuestion.id,
        answers: this.selectedAnswers,
      });
      if (this.isLastQuestion) {
        this.finishTest();
      } else {
        this.selectedAnswers = [];
        this.currentQuestionIndex++;
        window.scroll(0, 0);
      }
    },
    leaveTesting() {
      this.$store.commit("setTestState", false);
    },
    openSuccessTestModal(sertificateHash) {
      this.$dialog.show({
        title: "Поздравляем!",
        message: this.testInfo.isCertificatePrintable ?
          "Вы успешно прошли тестирование. Отправили вам на почту сертификат" : "Вы успешно прошли тестирование!",
        icon: "ico-modal-3.svg",
        closeOnCancel: false,
        buttons: {
          confirm: "Хорошо",
          cancel: this.testInfo.isCertificatePrintable && "Скачать в PDF",
        },
        onConfirm: this.leaveTesting,
        onClose: () => {
          this.downloadCert(sertificateHash);
          this.leaveTesting();
        },
      });
    },
    openTimeEndTestModal() {
      this.$dialog.show({
        title: "Вы не успели",
        message:
          "Время на прохождение тестирования закончилось, но вы можете попробовать пройти его ещё раз",
        icon: "ico-modal-4.svg",
        buttons: this.testInfo.isRepassingBlocked
          ? {
            confirm: null,
            cancel: "Выйти из теста",
          }
          : {
            confirm: "Пройти снова",
            cancel: "Не сейчас",
          },
        onConfirm: this.initTest,
        onClose: this.leaveTesting,
      });
    },
    openFailTestModal(correct, total) {
      this.$dialog.show({
        title: "Вы не прошли тестирование",
        message: `Вы ответили правильно на ${correct} из ${pluralize(total, [
            "вопрос",
            "вопросов",
            "вопросов",
          ])}. ${this.testInfo.isRepassingBlocked
            ? 'Для повторного прохождения Вы можете обратиться к своему администратору'
            : 'Вы можете улучшить свой результат прямо сейчас, начав тестирование заново'}`,
        icon: "ico-modal-5.svg",
        buttons: this.testInfo.isRepassingBlocked
          ? {
          confirm: null,
          cancel: "Выйти из теста",
          }
        : {
            confirm: "Пройти снова",
            cancel: "Не сейчас",
          }
        ,
        onConfirm: this.initTest,
        onClose: this.leaveTesting,
      });
    },
    async finishTest() {
      this.isFinished = true;
      this.pendingResults = true;
      try {
        let { data } = await api.tests.finishTest(this.testId, this.answers);
        this.pendingResults = false;
        this.timer.stop();
        switch (data.result) {
          case 1:
            this.openSuccessTestModal(data.certificateHash);
            break;
          case 2:
            this.openFailTestModal(data.correctAnswersCount, data.answersCount);
            break;
          case 3:
            this.openTimeEndTestModal();
            break;
        }
      } catch (eX) {
        console.error(eX);
        this.leaveTesting();
        this.pendingResults = false;
      }
    },
  },
  created() {
    this.initTest();
    this.timer = new Timer(this.onTick, this.onTimeOut);
    window.addEventListener('beforeunload', this.onCloseTab);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onCloseTab);
    this.$store.commit("setTestState", false);
    this.timer.stop();
  },
};
</script>

<style src="./test-body.less" lang="less" />
