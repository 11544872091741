import Vue from "vue";
import Vuex from "vuex";
import Auth from './modules/auth'
import Tests from './modules/tests'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lessVars: null,
  },
  mutations: {
    setLessVars(state, vars) {
      state.lessVars = JSON.parse(JSON.stringify(vars));
    },
  },
  actions: {},
  getters: {
    isDesktopOnly(state) {
      return !!window.matchMedia(state.lessVars.desktopOnly).matches;
    },
    isTablet(state) {
      return !!window.matchMedia(state.lessVars.tablet).matches;
    },
    isMobile(state) {
      return !!window.matchMedia(state.lessVars.mobile).matches;
    }
  },
  modules: {
    Auth,
    Tests
  }
});
