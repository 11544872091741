<template>
  <div class="answer-card">
    <div class="answer-card__image" v-if="image">
      <img :src="image" alt="" class="answer-card__image-img" />
    </div>
    <div class="answer-card__text" v-html="text" />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
    image: {
    },
    index: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style src="./answer-card.less" lang="less" />
