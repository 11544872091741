const state = {
  isTestActive: null,
}

const mutations = {
  setTestState(state, isActive) {
    state.isTestActive = isActive;
  },
}

export default {
  state,
  mutations
}