<template>
  <div class="page login">
    <div class="inner login__inner">
      <div class="login__content">
        <div class="login__logotype">
          <img
            src="../../assets/images/logotype.svg"
            alt=""
            class="login__logotype-img"
          />
        </div>
        <div class="login__title">Войдите в аккаунт</div>
        <div class="login__text">Используйте свой email и пароль</div>
        <div class="login__form">
          <input-block
            type="email"
            class="login__input"
            :error="$v.dataLogin.email.$error || !!error"
            v-model="dataLogin.email"
            placeholder="Email"
            ref="passwordInput"
            @on-enter="onLoginEnter"
            @on-blur="$v.dataLogin.email.$touch()"
          />
          <input-block
            ref="passwordInput"
            type="password"
            class="login__input"
            :error="$v.dataLogin.password.$error || !!error"
            v-model="dataLogin.password"
            placeholder="Пароль"
            @on-enter="onPasswordEnter"
            @on-blur="$v.dataLogin.$touch()"
          />
          <div class="login__errors" ref="errors">
            <p
              class="login__error"
              v-if="$v.dataLogin.email.$error && !$v.dataLogin.email.required"
            >
              Email не указан
            </p>
            <p
              class="login__error"
              v-if="$v.dataLogin.email.$error && !$v.dataLogin.email.email"
            >
              Некорректный email
            </p>
            <p
              class="login__error"
              v-if="
                $v.dataLogin.password.$error && !$v.dataLogin.password.required
              "
            >
              Пароль не указан
            </p>
            <p class="login__error" v-if="error" v-html="error"></p>
            <span class="login__reset" v-if="error" @click="resetPassword"
              >Восстановить пароль</span
            >
          </div>
        </div>
        <button-block
          :pending="pending"
          :disabled="$v.$anyError"
          class="login__button"
          @click.native="signIn"
        >
          Войти
        </button-block>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import { required, email } from "vuelidate/lib/validators";
import ButtonBlock from "../../components/button/button";
import InputBlock from "../../components/input/input";
import smoothHeight from "vue-smooth-height";

export default {
  components: {
    ButtonBlock,
    InputBlock,
  },
  mixins: [smoothHeight],
  validations: {
    dataLogin: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  data() {
    return {
      error: null,
      pending: false,
      dataLogin: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    async signIn() {
      this.error = null;
      this.$v.dataLogin.$touch();
      if (this.$v.$anyError) return;

      try {
        this.pending = true;
        const data = await api.auth.signIn(this.dataLogin);
        this.$store.dispatch("login", data.token);
        this.$store.dispatch("getProfile").then(() => {
          this.$router.push({ path: "/" });
        });
        this.pending = false;
      } catch (error) {
        this.pending = false;
        if (error?.request?.status === 400) {
          this.error = "Неверный email или пароль";
        } else {
          this.$dialog.show({
            title: "Ошибка",
            icon: "ico-modal-5.svg",
            message: error?.data?.message || "Не удается авторизоваться",
          });
        }
      }
    },
    onLoginEnter() {
      this.$refs.passwordInput.focus();
      this.$v.dataLogin.email.$touch();
    },
    onPasswordEnter() {
      this.signIn();
    },
    async resetPassword() {
      this.pending = true;
      try {
        const data = await api.auth.resetPassword(this.dataLogin.email);
        this.pending = false;
        this.$dialog.show({
          title: "Успешно",
          message: `Ccылка для восстановления отправлена на ${this.dataLogin.email}`,
          icon: "ico-modal-4.svg",
        });
      } catch (e) {
        this.pending = false;
        this.$dialog.show({
          title: "Ошибка",
          message: e.data.message,
          icon: "ico-modal-5.svg",
        });
      }
    },
  },
  watch: {
    "dataLogin.email": function () {
      this.error = null;
    },
    "dataLogin.password": function () {
      this.error = null;
    },
  },
  mounted() {
    this.$smoothElement({
      el: this.$refs.errors,
    });
  },
};
</script>

<style src="./login.less" lang="less" />
