<template>
  <header class="header">
    <div class="inner header__inner">
      <router-link to="/" class="header__logotype" v-if="!isTestActive">
        <img
          src="../../assets/images/logotype.svg"
          alt=""
          class="header__logotype-img"
        />
      </router-link>
      <router-link to="/" tag="div" class="header__logotype" v-else>
        <div class="header__exit" v-if="isTestActive">
          <div class="header__exit-ico"></div>
          <p class="header__exit-text">Выйти</p>
        </div>
      </router-link>
      <div class="header__box">
        <div class="header__name">{{ computedName }}</div>
        <button-block
          size="login"
          type="login"
          class="header__logout"
          @click.native="openExitDialog"
          >Выйти из аккаунта</button-block
        >
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions } from "vuex";
import ButtonBlock from "../../components/button/button";

export default {
  components: {
    ButtonBlock,
  },
  methods: {
    ...mapActions({
      logout: "logout",
    }),
    openExitDialog() {
      this.$dialog.show({
        title: "Вы уверены, что хотите выйти из аккаунта?",
        closeOnCancel: false,
        buttons: {
          confirm: "Да",
          cancel: "Нет",
        },
        onConfirm: this.logout,
      });
    },
  },
  computed: {
    profile() {
      return this.$store.state.Auth.profile;
    },
    fullname() {
      if (!this.profile) return null;

      return [
        this.profile.lastName ?? "",
        this.profile.firstName ?? "",
        this.profile.middleName ?? "",
      ].map((i) => i.charAt(0).toUpperCase() + i.slice(1));
    },
    computedName() {
      let fullname = this.fullname;

      if (!fullname) return "";
      if (this.$store.getters.isMobile) {
        fullname[1] = fullname[1] ? `${fullname[1].substring(0, 1)}.` : "";
        fullname[2] = fullname[2] ? `${fullname[2].substring(0, 1)}.` : "";
      }
      return fullname.join(" ");
    },
    isTestActive() {
      return this.$store.state.Tests.isTestActive;
    },
  },
};
</script>

<style src="./header.less" lang="less" />
