<template>
  <div class="test-description">
    <div class="test-description__inner">
      <div v-if="isScormTest" class="test-description__status">
        Интерактивный тренинг
      </div>
      <div v-else class="test-description__status">
        {{`${timeForPassingTestText} • ${questionsQuantityText}` }}
      </div>
      <h2 class="test-description__title">
        {{ test.title }}
      </h2>
      <button-block
        v-if="this.test.isPassed && !isScormTest && isFullFilledName && test.isCertificatePrintable"
        @click.native="downloodPdf"
        class="test-description__button"
      >
        Скачать сертификат
      </button-block>
      <button-block
        v-else-if="showStartBtn"
        @click.native="openStartTestingDialog"
        class="test-description__button"
      >
        {{ openTestBttonLabel }}
      </button-block>
      <div
          class="test-description__body"
      >
        <div
          class="test-description__body-inner"
        >
            <div
              ref="text"
              class="test-description__cover-text"
              v-html="test.description"
            />
        </div>
      </div>
      <img
        v-if="test.imageUrl"
        :src="test.imageUrl"
        alt="#"
        class="test-description__image"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonBlock from "../../components/button/button";
import { pluralize, minToHours } from "../../tools";

export default {
  components: {
    ButtonBlock,
  },
  props: {
    test: {
      type: Object,
      required: true,
    }
  },

  methods: {
    openStartTestingDialog() {
      if (this.isScormTest) {
        this.$store.commit("setTestState", true);
      }
      else {
        this.$dialog.show({
          title: "Время ограничено",
          message: `У вас будет ${this.timeForPassingTestText} на полное прохождение теста. Вы точно готовы?`,
          icon: "ico-modal-1.svg",
          buttons: {
            confirm: "Готов",
            cancel: "Не готов",
          },
          onConfirm: () => {
            this.$store.commit("setTestState", true);
          },
        });
      }
    },
    downloodPdf() {
      this.downloadCert(this.test.certificateHash);
    },
  },

  computed: {
    ...mapGetters({
      isFullFilledName: "isFullFilledName"
    }),
    timeForPassingTestText() {
      return minToHours(this.test?.timeForPassingTest);
    },
    questionsQuantityText() {
      return pluralize(this.test?.questionsQuantity, ['вопрос', 'вопроса', 'вопросов'])
    },
    isScormTest() {
      return this.test.type === "scorm"
    },
    showStartBtn() {
      if (this.test.isAvailable) {
        return !this.test.isPassed || this.isScormTest
      } else {
        return !this.test.isRepassingBlocked && this.test.isAvailable
      }
    },
    height() {
      return  this.isOpen ? this.$refs.text.clientHeight : this.initialHeight
    },
    openTestBttonLabel() {
      const scormData = JSON.parse(localStorage.getItem('scormData') || '{}');
      const isHaveCourseData = scormData?.[this.test.id]?.[this.test.hash]?.['cmi.suspend_data'] || false;
      return isHaveCourseData ? "Продолжить" : "Начать";
    }
  },
};
</script>

<style src="./test-description.less" lang="less" />
