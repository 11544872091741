<template>
    <div class="scorm-player">
        <iframe
            class="scorm-player__iframe"
            ref="iframe"
            :src="scormPath"
            frameborder="0"
        ></iframe>
    </div>
</template>

<script>
export default {
    name: "ScormPlayer",
    props: {
        id: {
            type: [Number, String],
            required: true
        },
        hash: {
            type: String,
            required: true
        },
        path: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            scormPath: `${this.path}/index_lms.html`,
            isCompleted: false,
            observer: null,
        };
    },
    mounted() {
        window.API_1484_11 = new ScormAPI(this);
    },
    beforeDestroy() {
        delete window.API_1484_11;
        if (this.observer) {
            this.observer.disconnect();
        }
        window.removeEventListener('resize', this.setIframeSize);
    },
    methods: {
        initialize() {
            this.initializeIframeObserver();
            this.setIframeStyles();
            this.observeIframe();
            window.addEventListener('resize', this.setIframeSize);
        },
        observeIframe() {
            const { iframe } = this.$refs;
            const { contentWindow } = iframe;

            if (contentWindow && contentWindow.document) {
                const observer = new MutationObserver((mutations, obs) => {
                    const frame = contentWindow.document.querySelector('#frame');
                    if (frame) {
                        this.setIframeFullscreenButton();
                        this.setModalCaption();
                        obs.disconnect();
                    }
                });
                observer.observe(contentWindow.document.body, {
                    childList: true,
                    subtree: true
                });
            }
        },
        setIframeStyles() {
            const iframe = this.$refs.iframe;

            if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
                let style = iframe.contentWindow.document.createElement('style');
                style.innerHTML = `
                    #frame {
                        border: initial;
                        border-radius: initia;
                        background: initial;
                        border: 2px solid #F2F2F2 !important;
                        border-radius: 20px !important;
                    }

                    #slide-window {
                        border-radius: 14px !important;
                        overflow: hidden;
                    }

                    .presentation-title.cs-title {
                        display: none;
                    }

                    .cs-window {
                        background-color: #ffffff !important;
                        border-radius: 14px !important;
                        box-shadow: initial !important;
                        border: initial !important;
                    }

                    #message-window-slide {
                        border: 2px solid #F2F2F2 !important;
                    }

                    #message-window-slide button {
                        color: black !important;
                        border: 2px solid #F2F2F2 !important;
                        background: initial !important;
                        text-shadow: initial !important;
                        border-radius: 20px;
                    }

                    #message-window-slide button svg {
                        margin-top: -5px;
                    }

                    #message-window-slide div[data-acc-text="Да"] button {
                        color: #ffffff !important;
                        background-color: #5584FF !important;
                        border: 2px solid #4870D9 !important;
                    }

                    #message-window-slide div[data-acc-text="Да"] button svg text {
                        fill: white !important;
                    }

                    #message-window-slide #message-window-slide hr {
                        display: none;
                    }

                    .cs-menu {
                        background: white !important;
                        box-shadow: initial !important;
                        border: 2px solid #F2F2F2 !important;
                        overflow: hidden;
                    }

                    .cs-listitem.cs-selected {
                        color: #ffffff !important;
                        background-color: #5584FF !important;
                    }
                `;
                iframe.contentWindow.document.head.appendChild(style);
            }
        },
        setIframeFullscreenButton() {
            const { iframe } = this.$refs;
            const { contentWindow } = iframe;

            if (contentWindow && contentWindow.document) {
                const { document } = contentWindow;
                const fsBtn = document.createElement('button');
                fsBtn.innerHTML = 'Перейти в полноэкранный режим';
                fsBtn.className = 'fs-button';

                const css = `
                    .fs-button {
                        position: absolute;
                        top: 0.7vw;
                        right: 0.7vw;
                        z-index: 1000;
                        color: black;
                        border: 2px solid #F2F2F2;
                        background: white;
                        border-radius: 20px;
                        padding: 0.5vw 1vw;
                        cursor: pointer;
                        font-size: 1vw;
                    }
                    .fs-button:hover {
                        // background-color: #0056b3;
                    }
                `;

                const style = document.createElement('style');
                const coureFrame = document.querySelector('#frame');
                style.textContent = css;
                document.head.appendChild(style);
                coureFrame.appendChild(fsBtn);

                fsBtn.addEventListener('click', () => {
                    if (!document.fullscreenElement) {
                        fsBtn.innerHTML = 'Выйти из полноэкранного режима';
                        contentWindow.document.documentElement.requestFullscreen();
                    } else {
                        fsBtn.innerHTML = 'Перейти в полноэкранный режим';
                        contentWindow.document.exitFullscreen();
                    }
                })
            }
        },
        initializeIframeObserver() {
            const iframe = this.$refs.iframe;
            if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
                this.observer = new MutationObserver(this.setIframeSize);
                this.observer.observe(iframe.contentWindow.document.body, {
                    childList: true,
                    subtree: true,
                    attributes: true
                });
                this.setIframeSize();
            }
        },
        setIframeSize() {
            const iframe = this.$refs.iframe;
            if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
                const frame = iframe.contentWindow.document.getElementById('wrapper');
                let aspectRatio;

                if (frame) {
                    aspectRatio = frame.offsetWidth / frame.offsetHeight;
                } else {
                    aspectRatio = 16 / 9;
                }

                iframe.style.height = `${iframe.offsetWidth / aspectRatio}px`;
            }
        },
        setModalCaption() {
            const { iframe } = this.$refs;
            const { contentWindow } = iframe;

            if (contentWindow && contentWindow.document) {
                const { document } = contentWindow;
                const caption = document.querySelector('div[data-acc-text="Продолжить обучение с того места, на котором ты остановился?"]')

                if (caption) {
                    const captionSvg = caption.querySelector('.slideobject-maskable');
                    captionSvg.style.display = 'none';
                    caption.style.fontSize = '1.2vw';
                    caption.style.lineHeight = '1.2em';
                    caption.innerHTML += "Продолжить обучение с того места, на котором Вы остановились?"
                }

            }
        },
        getValue(key) {
            try {
                const scormData = JSON.parse(localStorage.getItem('scormData') || '{}');
                return scormData?.[this.id]?.[this.hash]?.[key] || null;
            } catch (error) {
                console.error('Error reading from scormData:', error);
                return null;
            }
        },

        setValue(key, value) {
            try {
                if (value === "completed") {
                    this.isCompleted = value;
                }

                const scormData = JSON.parse(localStorage.getItem('scormData') || '{}');
                if (!scormData[this.id]) {
                    scormData[this.id] = {};
                }
                if (!scormData[this.id][this.hash]) {
                    scormData[this.id][this.hash] = {};
                }
                scormData[this.id][this.hash][key] = value;

                localStorage.setItem('scormData', JSON.stringify(scormData));
                this.$emit('set-value', key, value);
            } catch (error) {
                console.error('Error writing to scormData:', error);
            }
        },

        terminate() {
            try {
                const scormData = JSON.parse(localStorage.getItem('scormData') || '{}');

                if (scormData?.[this.id]?.[this.hash] && this.isCompleted) {
                    delete scormData[this.id][this.hash];
                    localStorage.setItem('scormData', JSON.stringify(scormData));
                }

                this.$emit('finish');
            } catch (error) {
                console.error('Error removing hash from scormData:', error);
            }
        },
    },
};

class ScormAPI {
    constructor(component) {
        this.component = component;
    }
    Initialize() {
        console.log("SCORM API: Initialize");
        this.component.initialize()
        return true;
    }
    SetValue(key, value) {
        this.component.setIframeSize();
        return this.component.setValue(key, value);
    }
    GetValue(key) {
        return this.component.getValue(key);
    }
    Terminate() {
        this.component.terminate();
        return true
    }
    Commit() {
    }
    GetLastError() {
    }
    GetErrorString()  {
    }
    GetDiagnostic()  {
    }
}
</script>

<style src="./scorm-player.less" lang="less"></style>
