import { getInstance } from '../tools';

export function signIn(data) {
    return getInstance().post('/api/auth/sign-in', data);
}

export function invitePassword({ inviteToken, password }) {
    return getInstance().post('/api/auth/sign-up-by-invite-token', { inviteToken, password });
}

export function isValidToken(token) {
    return getInstance().post('/api/auth/check-invite-token-exists', {inviteToken: token});
}

export function resetPassword(email) {
    return getInstance().post('/api/auth/reset-password', {email})
}