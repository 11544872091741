<template>
  <div :class="bem('app', { type: `${$route.name}` })">
    <dialog-block />
    <div class="app__wrapper">
      <header-block v-if="showFooterHeader" class="app__header" />
      <router-view class="app__view" />
      <footer-block v-if="showFooterHeader" class="app__footer" />
    </div>
  </div>
</template>

<script>
import HeaderBlock from "./components/header/header";
import FooterBlock from "./components/footer/footer";
import DialogBlock from "./components/dialog/dialog";
import lessVars from "./assets/styles/vars.less";

export default {
  components: {
    HeaderBlock,
    FooterBlock,
    DialogBlock,
  },
  computed: {
    showFooterHeader() {
      return this.$route.name !== "login" || this.$route.name !== "invite";
    },
  },
  created() {
    this.onWindowResize();
    window.addEventListener("resize", this.onWindowResize);

    this.$store.dispatch("checkingAvailabilityToken");
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      this.$store.commit("setLessVars", lessVars);
    },
  },
};
</script>

<style src="./app.less" lang="less" />
