<template>
  <div class="page invite">
    <div class="inner invite__inner">
      <div class="invite__content">
        <div class="invite__logotype">
          <img
            src="../../assets/images/logotype.svg"
            alt=""
            class="invite__logotype-img"
          />
        </div>
        <div class="invite__title">Установка пароля</div>
        <div class="invite__form">
          <input-block
            type="password"
            class="invite__input"
            :error="$v.dataPassword.repeatPassword.$error"
            v-model="dataPassword.password"
            placeholder="Пароль"
            ref="passwordInput"
            @on-enter="onPasswordEnter"
            @on-blur="$v.dataPassword.password.$touch()"
          />
          <input-block
            ref="passwordInput"
            type="password"
            class="invite__input"
            :error="$v.dataPassword.repeatPassword.$error"
            v-model="dataPassword.repeatPassword"
            placeholder="Повторите пароль"
            @on-blur="$v.dataPassword.$touch()"
          />
          <div class="invite__errors" ref="errors">
            <p
              class="invite__error"
              v-if="$v.dataPassword.repeatPassword.$error"
            >
              Пароли не совпадают
            </p>
          </div>
        </div>
        <button-block
          :pending="pending"
          :disabled="$v.$anyError"
          class="invite__button"
          @click.native="invitePassword"
        >
          Установить
        </button-block>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import { required, sameAs } from "vuelidate/lib/validators";
import ButtonBlock from "../../components/button/button";
import InputBlock from "../../components/input/input";

export default {
  props: {
    token: String,
  },
  components: {
    ButtonBlock,
    InputBlock,
  },
  validations: {
    dataPassword: {
      password: {
        required,
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  data() {
    return {
      pending: false,
      dataPassword: {
        password: null,
        repeatPassword: null,
      },
    };
  },
  methods: {
    async invitePassword() {
      this.$v.dataPassword.$touch();

      if (this.$v.$anyError) return;

      try {
        this.pending = true;
        const data = await api.auth.invitePassword({
          inviteToken: this.token,
          password: this.dataPassword.password,
        });

        this.pending = false;
        this.$router.push("/login");
      } catch (error) {
        this.pending = false;

        this.$dialog.show({
          title: "Ошибка",
          icon: "ico-modal-5.svg",
          message: error?.data?.message || "Не удается задать пароль",
        });
      }
    },
    onPasswordEnter() {
      this.$refs.passwordInput.focus();
      this.$v.dataPassword.repeatPassword.$touch();
    },
  },
  async created() {
    const validToken = await api.auth.isValidToken(this.token);
    if (!validToken.data.exists) {
      this.$router.push("/login");
    }
  },
};
</script>

<style src="./invite.less" lang="less" />