<template>
  <div class="page home">
    <div class="inner home__inner">
      <div class="home__title">Доступные сценарии</div>
      <div class="home__content">
        <transition name="fade" mode="out-in">
          <loader-block
            style="font-size: 40px; height: 30vh"
            v-if="pending"
          ></loader-block>
          <div v-else>
            <div class="home__section">
              <p v-if="availableTests.length < 1" class="home__no-avalible">Нет доступных тестов</p>
              <div class="home__section-content">
                <TestCard
                  v-for="(test, index) in availableTests"
                  :id="test.id"
                  :hash="test.hash"
                  :passed="false"
                  :info="getInfoText(test)"
                  :title="test.title"
                  :description="test.shortDescription"
                  :type="test.type"
                  :isScormPassed = test.isScormPassed
                  :is-available="test.isAvailable"
                  :is-certificate-printable="test.isCertificatePrintable"
                  :key="index"
                  class="home__item"
                  @click-pass="openTest(test)"
                />
              </div>
            </div>
            <div class="home__section" v-if="passedTest.length > 0">
              <div class="home__title">Пройденные</div>
              <div class="home__section-content">
                <TestCard
                  v-for="(test, index) in passedTest"
                  :passed="true"
                  :info="getInfoText(test)"
                  :title="test.title"
                  :description="test.shortDescription"
                  :is-available="test.isAvailable"
                  :is-certificate-printable="test.isCertificatePrintable"
                  :isScormPassed = test.isScormPassed
                  :type="test.type"
                  :key="index"
                  class="home__item"
                  @click-pass="openTest(test)"
                />
              </div>
            </div>

            <div class="home__section" v-if="blockedTest.length > 0">
              <div class="home__title"> Не пройденные</div>
              <div class="home__section-content">
                <TestCard
                  v-for="(test, index) in blockedTest"
                  :passed="true"
                  :info="getInfoText(test)"
                  :title="test.title"
                  :description="test.shortDescription"
                  :is-certificate-printable="test.isCertificatePrintable"
                  :is-available="test.isAvailable"
                  :type="test.type"
                  :key="index"
                  class="home__item"
                  @click-pass="openTest(test)"
                />
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import TestCard from "../../components/test-card/test-card";
import ButtonBlock from "../../components/button/button";
import api from "../../api";
import { minToHours, pluralize } from "../../tools";
import LoaderBlock from "../../components/loader/loader.vue";

export default {
  components: {
    ButtonBlock,
    TestCard,
    LoaderBlock,
  },
  data() {
    return {
      availableTests: [],
      passedTest: [],
      blockedTest: [],
      pending: false,
    };
  },
  methods: {
    openTest(test) {
      this.$router.push(`/tests/${test.id}`);
    },
    getInfoText(test) {
      return `${this.timeForPassingTestText(
        test
      )} • ${this.questionsQuantityText(test)}`;
    },
    timeForPassingTestText(test) {
      return minToHours(test?.timeForPassingTest);
    },
    questionsQuantityText(test) {
      return pluralize(test?.questionsQuantity, [
        "вопрос",
        "вопроса",
        "вопросов",
      ]);
    },
  },
  async mounted() {
    this.pending = true;
    try {
      let response = await api.tests.getTests(true);
      response.items.forEach((el) => {
        el.isAvailable
          ? this.availableTests.push(el)
          : this.blockedTest.push(el)
      })

      response = await api.tests.getTests(false);
      response.items.forEach((el) => {
        if(el.type === 'scorm') {
          el.isScormPassed = true
        }
       this.passedTest.push(el)
      })
    } catch (eX) {
      console.error(eX);
    }
    this.pending = false;
  },
};
</script>

<style src="./home.less" lang="less" />
