<template>
  <transition name="fade" @before-leave="beforeClose" @before-enter="onOpen">
    <div v-if="open" class="dialog">
      <div class="dialog__outside" @click.self="close">
        <div class="dialog__container">
          <div class="dialog__header">
            <div class="dialog__icon">
              <img
                v-if="this.icon"
                :src="iconPath"
                alt="ico-modal-1"
                class="dialog__icon-element"
              />
            </div>
            <div class="dialog__close" @click="close"></div>
          </div>
          <p v-if="title" class="dialog__title">{{ title }}</p>
          <p v-if="message" class="dialog__message">{{ message }}</p>
          <div class="dialog__buttons">
            <button-block
              v-if="buttons.cancel"
              size="steps"
              class="dialog__button"
              type="secondary"
              @click.native="cancel"
            >
              {{ buttons.cancel }}
            </button-block>
            <button-block
              v-if="buttons.confirm"
              size="steps"
              class="dialog__button"
              @click.native="confirm"
              >{{ buttons.confirm }}</button-block
            >
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ButtonBlock from "../../components/button/button";

export default {
  name: "DialogBlock",
  components: {
    ButtonBlock,
  },
  data() {
    return {
      open: false,
      title: null,
      message: null,
      icon: null,
      closeOnConfirm: null,
      closeOnCancel: null,
      closeOnClose: null,
    };
  },
  mounted() {
    this.$dialog.$on("show", this.openDialog);
    this.$dialog.$on("close", this.openDialog);
  },
  computed: {
    iconPath() {
      return this.icon ? `/static/images/${this.icon}` : null;
    },
  },
  methods: {
    confirm() {
      this.onConfirm();
      this.closeOnConfirm && (this.open = false);
    },
    cancel() {
      this.onCancel();
      this.open = false;
    },
    close() {
      this.onClose();
      this.open = false;
    },
    openDialog({
      title,
      buttons = {},
      message,
      icon,
      closeOnConfirm = true,
      closeOnCancel = true,
      closeOnClose = true,
      onConfirm,
      onCancel,
      onClose = () => {},
    }) {
      document.body.classList.add("no-scroll");
      this.title = title;
      this.message = message;
      this.icon = icon;
      this.buttons = buttons;
      this.closeOnConfirm = closeOnConfirm;
      this.closeOnCancel = closeOnCancel;
      this.closeOnClose = closeOnClose;
      this.open = true;
      this.onConfirm = onConfirm || onClose;
      this.onCancel = onCancel || onClose;
      this.onClose = onClose;
    },
    onOpen() {
      document.body.classList.remove("no-scroll");
    },
    beforeClose() {
      document.body.classList.remove("no-scroll");
    },
  },
};
</script>

<style src="./dialog.less" lang="less" />
