<template>
  <div :class="bem('label', { status: classLabel })">
    <div class="label__text">
      {{ textLabel }}
    </div>
    <div class="label__icon" />
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Number,
      default: null,
    },
  },
  computed: {
    textLabel() {
      const statuses = ["Начат", "Пройден", "Не пройден", "Вышло время"];

      return statuses[this.status] || "Отменен";
    },
    classLabel() {
      const statuses = ["waiting", "success", "cancelled", "cancelled"];

      return statuses[this.status] || "";
    },
  },
};
</script>

<style src="./label.less" lang="less" />
