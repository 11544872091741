import Vue from 'vue';

import router from '../../router';
import api from '../../api';
import { setToken, deleteToken, getToken } from '../../tools/tokenStore';

const state = {
  isAuth: false,
  token: null,
  profile: null,
}

const mutations = {
  login(state, token) {
    state.token = token;
  },
  logout(state) {
    state.token = null;
    state.profile = null;
  },
  setProfile(state, value) {
    state.profile = value;
  },
}

const actions = {
  login({ commit }, token) {
    setToken({
      value: token,
      duration: 'long'
    });
    commit('login', token);
  },
  async logout({ commit }) {
    commit('logout');
    deleteToken();
    await Vue.nextTick();
    router.push('/login');
  },
  async getProfile({ commit }) {
    const response = await api.profile.getProfile();
    commit('setProfile', response.data);
    return response.data;
  },
  checkingAvailabilityToken({ commit, dispatch }) {
    const token = getToken();
    if (token) {
      dispatch('getProfile');
    }
  },
}

const getters = {
  isAuthenticated(state) {
    return !!state.profile;
  },
  isFullFilledName(state) {
    return state.profile.firstName && state.profile.middleName && state.profile.lastName
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
