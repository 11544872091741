import Vue from "vue";
import VueRouter from "vue-router";
import App from "./app.vue";
import "./assets/styles/main.less";
import router from "./router";
import store from "./store";
import Vuelidate from 'vuelidate'
import "./mixins/global";

Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, "$dialog", {
  get: function () {
    return this.$root.dialog;
  },
});

export default new Vue({
  el: ".app",
  router,
  store,
  render: (h) => h(App),
  data: {
    dialog: new Vue({
      methods: {
        show(params) {
          this.$emit("show", { ...params });
        },
        hide(name) {
          this.$emit("hide");
        },
      },
    }),
  },
});
