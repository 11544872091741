export default class Timer {
  constructor(onTick = () => { }, onTimeOut = () => { },) {
    this.t = null,
      this.seconds = null,
      this.minutes = null,
      this.hours = null,
      this.interval = null,
      this.deadline = null;
    this.time = null,
      this.onTick = onTick;
    this.onTimeOut = onTimeOut;
    this.initializeClock();
  }

  getTimeRemaining(endtime) {
    this.t = Date.parse(endtime) - (Date.parse(new Date()));
    this.seconds = Math.floor((this.t / 1000) % 60);
    this.minutes = Math.floor((this.t / 1000 / 60) % 60);
    this.hours = Math.floor((this.t / (1000 * 60 * 60)) % 24);

    return {
      'total': this.t,
      'hours': this.hours,
      'minutes': this.minutes,
      'seconds': this.seconds
    };
  }

  steptInterval(endtime) {
    var t = this.getTimeRemaining(endtime);
    let tickTime = `${`${t.hours}`.padStart(2, '0')}:${`${t.minutes}`.padStart(2, '0')}:${`${t.seconds}`.padStart(2, '0')}`;
    this.onTick(tickTime)
    if (t.total <= 0) {
      clearInterval(this.interval);
      this.onTimeOut();
    }
  };

  initializeClock() {
    this.onTick('--:--:--')
  }

  start(startTime, durationMins) {
    const offset = Date.now() - new Date(startTime).getTime();
    this.stop();
    this.deadline = new Date(Date.parse(this.getDate(startTime)) + durationMins * 60 * 1000 + offset);
    this.steptInterval(this.deadline);
    this.interval = setInterval(() => {
      this.steptInterval(this.deadline)
    }, 1000)
  }

  stop() {
    clearInterval(this.interval);
  }

  save() {
    return this.time;
  }

  getDate(date) {
    const
      values = date.split(/[^0-9]/),
      year = parseInt(values[0], 10),
      month = parseInt(values[1], 10) - 1,
      day = parseInt(values[2], 10),
      hours = parseInt(values[3], 10),
      minutes = parseInt(values[4], 10),
      seconds = parseInt(values[5], 10);
    return new Date(year, month, day, hours, minutes, seconds)
  }
}
