import { render, staticRenderFns } from "./scorm-player.vue?vue&type=template&id=b96504f8&"
import script from "./scorm-player.vue?vue&type=script&lang=js&"
export * from "./scorm-player.vue?vue&type=script&lang=js&"
import style0 from "./scorm-player.less?vue&type=style&index=0&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports